<template>
    <section>
        <div class="container">
            <h2 class="pb-2">{{ titulo != null ? titulo : this.title != null ? this.title : '' }}</h2>
            <div class="row row-cols-2 row-cols-md-6 g-4 text-center" v-if="!items">
                <div class="col" v-for="n in 6" :key="n">
                    <div class="card">
                        <span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white loading-card-second">
                        </span>
                        <div class="card-body">
                            <h3 class="my-0 h6">
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-cols-2 row-cols-md-6 g-4 text-center">
                <div class="col" v-for="(el, i) in items" :key="i">
                    <div class="card">
                        <span class="card-img-top p-3 text-white" style="background-color: RGBA(255,130,0)!important;">
                            <img v-if="el.icon.startsWith('@/assets')" :src="el.resolvedIcon" alt="icon"
                                class="img-fluid ptn-i-2x p-1" :class="[iconosGrandes ? 'ptn-i-3x' : '']" />
                            <svg v-else-if="el.icon.includes('ptn')" class="ptn-i-2x"
                                :class="[iconosGrandes ? 'ptn-i-3x' : '']">
                                <use :xlink:href="'#' + el.icon"></use>
                            </svg>
                            <span v-else class="material-symbols-outlined text-white ptn-i-2x picons"
                                :class="[iconosGrandes ? 'ptn-i-3x' : '']">{{ el.icon }}</span>
                        </span>
                        <div class="card-body">
                            <h3 class="my-0 h6">
                                <a :href="el.url" :target="el.target ? el.target : '_self'" class="stretched-link">{{
                                    el.title }}</a>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.picons{
    margin-top: -6px;
}
</style>
<script>
const axios = require("axios");

export default {
    name: "BoxIconGridComponent",
    props: {
        titulo: String,
        subtitulo: String,
        filtros: Object,
        data: Object,
        iconosGrandes: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hash: "BIGC",
            items: null,
            title: null,
            desc: null
        };
    },
    methods: {
        async resolveIcons() {
            if (this.items) {
                for (let item of this.items) {
                    if (item.icon.startsWith('@/assets')) {
                        try {
                            item.resolvedIcon = (await import(`@/assets${item.icon.replace('@/assets', '')}.png`)).default;
                        } catch (e) {
                            console.error(`Error loading image: ${item.icon}`, e);
                            item.resolvedIcon = '';
                        }
                    } else {
                        item.resolvedIcon = item.icon;
                    }
                }
            }
        }
    },
    async mounted() {
        if (this.data == null) {
            let params = {};
            if (this.filtros != null && this.filtros != "") {
                params = this.filtros;
            }
            params.tipo = this.hash;
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL, params);
                this.items = response.data ? response.data.items : null;
                this.title = response.data ? response.data.title : null;
                this.desc = response.data ? response.data.desc : null;
                localStorage.setItem(this.hash, JSON.stringify(response));
                await this.resolveIcons();
            } catch (error) {
                if (!error.response) {
                    console.log("Fuera de Línea");
                    if (localStorage.getItem(this.hash)) {
                        console.log("Cargando elementos de caché para " + this.hash);
                        var resp = JSON.parse(localStorage.getItem(this.hash));
                        this.items = resp.data ? resp.data.items : null;
                        this.title = resp.data ? resp.data.title : null;
                        this.desc = resp.data ? resp.data.desc : null;
                        await this.resolveIcons();
                    }
                }
            }
        } else {
            this.items = this.data;
            await this.resolveIcons();
        }
    }
};
</script>

<style scoped>
    .img-fluid{
        height: 65px;
        width: 65px;
    }
</style>